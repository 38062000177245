// extracted by mini-css-extract-plugin
export var addressBox = "footer-module--addressBox--b489d";
export var col = "footer-module--col--ff68a";
export var column = "footer-module--column--a4ac3";
export var contact = "footer-module--contact--02eef";
export var container = "footer-module--container--21178";
export var copyright = "footer-module--copyright--5b8ea";
export var desktopFooterContainer = "footer-module--desktopFooterContainer--cecff";
export var flexbox = "footer-module--flexbox--627a3";
export var link = "footer-module--link--d727c";
export var links = "footer-module--links--d956e";
export var logo = "footer-module--logo--b3056";
export var mobileFooterContainer = "footer-module--mobileFooterContainer--61cce";
export var social = "footer-module--social--f1c6e";