import React from "react"
import cn from "classnames"

import * as styles from "./footer.module.css"
import TransitionLink from "../../TransitionLink"
import content from "../../../content/footer"

const footer = content.footer

export default function Footer() {
  return (
    <footer>
      <section className={styles.container}>
        <article className={styles.flexbox}>
          <div className={styles.col}>
            <div className={styles.logo}>
              <img alt="Logo" src={footer.logo} />
            </div>
          </div>
          <div className={styles.col} id={styles.links}>
            {footer.links.map((linkItem, index) => (
              <div className={styles.links} key={index}>
                <TransitionLink
                  cover
                  direction="up"
                  bg="#D1CAC4"
                  to={linkItem.path}
                  id={`footerLink ${index + 1}`}
                >
                  <span className={styles.link}>{linkItem.title}</span>
                </TransitionLink>
                {"\n"}
              </div>
            ))}
          </div>
          <div className={styles.col}>
            <article>
              <p>5820 Allentown Way</p>
              <p>Temple Hills, MD 20748</p>
              <p>United States • 202-439-2950</p>
              <p>Sales@mdkitchenbath.com</p>
            </article>
          </div>
        </article>
        <article className={styles.copyright}>
          <p>{content.footer.copy}</p>
        </article>
      </section>
    </footer>
  )
}
